import React from 'react';
import Layout from '../components/Layout';

import Dibujo3d from '../../static/images/solutions/linea_de_lavado/c2.png'

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/linea_de_lavado/2.jpg)`,
                        }}
                    >
                        <h1>Línea de lavado de botellas de PET de grado de filamento micro denier</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Las personas anhelan una vida mejor de buena calidad en medio del desarrollo sustentable
                                de la economía global, y buscan comodidades ecológicas diariamente. Las principales
                                marcas del mundo promueven un concepto de consumo más respetuoso con el medio ambiente
                                al tiempo que generan beneficios. Estas primeras marcas han anunciado que aumentarán el
                                contenido reciclado e incluso adoptarán material de 100% reciclado en sus productos,
                                como camisetas, zapatos, etc. Además, eso le brindará al cliente una mejor experiencia
                                en la vida de consumo.
                                <br/>

                                Las botellas de PET post-consumo son recicladas en filamentos de micro denier, a través
                                de procesos de trituración, lavado, peletizado e hilado, en la cuales son aplicadas
                                ampliamente en la industrias posteriores, como telas impermeables y antiperforaciones de
                                alta densidad, materiales de absorción funcional, ropa interior de punto de alta gama,
                                materiales filtrantes, materiales hidratantes, etc. Los productos fabricados con
                                filamentos de micro denier son más flexibles, amigables con la piel y ecológicos. El
                                filamento reciclado como material ecológico es muy buscado por el mercado y tiene un
                                buen desarrollo en el mercado.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Característica de producto</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                La línea de reciclaje de botellas de PET de grado de filamento de micro denier Zion Tech,
                                está desarrollada para las marcas posteriores, como Adidas, Nike, IKEA, etc., que
                                requieren filamento reciclado y ecológico. Las escamas de rPET después de la granulación
                                se introducirán en la línea de producción de fibra e hilado en filamento de micro
                                denier.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                La línea de reciclaje de botellas de PET de grado de filamento de micro denier de
                                Zion Tech adopta un diseño de proceso modular, piezas de repuesto estándar internacional.
                                Está disponible para ajustar y optimizar los módulos de proceso de acuerdo con las pacas
                                de botellas de PET en diferentes países y regiones. Este sistema está equipado con un
                                sistema de recolección de subproductos en línea, mejorando el valor agregado de estos
                                subproductos y disminuyendo las labores. La conexión entre los dispositivos adopta
                                transporte neumático y de bombeo, cada posición del equipo es removible, es conveniente
                                para el mantenimiento diario y la optimización del proceso debido a cambios de
                                materiales en el futuro.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                Las escamas de rPET de grado de filamento de micro denier tienen un requisito estándar
                                más alto que las de grado alimenticio, especialmente en las impurezas. El filamento de
                                micro denier requiere escamas de rPET con menos impurezas al girar. De modo que el
                                sistema de circulación de agua de esta línea de reciclaje de botellas de PET de grado se
                                actualiza según el grado de calidad alimentaria. Las aguas residuales se filtran a
                                través del prelavado de botellas, lavado en caliente y enjuague, estos tratamientos de
                                agua tienen circulación de 3 pasos, las aguas residuales se recuperarán al estándar de
                                agua de la industria y se reutilizarán. Las escamas de rPET y los productos químicos
                                residuales se pueden separar completamente después de enjuagar con abundante agua dulce.
                                Puede minimizar el contenido de impurezas en las escamas de PET reciclado. Este tipo de
                                escamas de rPET ha pasado la prueba de contrapresión de las marcas.
                            </p>
                            <br/>
                            <p style={{color: '#000'}}>
                                El sistema de reciclaje de botellas de PET de grado de filamento de micro denier
                                Zion Tech adopta un sistema SCADA combinado con un controlador PLC, que puede mostrar
                                datos de parámetros de cada unidad y equipo individual de manera intuitiva y efectiva, y
                                al mismo tiempo el sistema puede registrar todos los datos de producción, y tiene
                                combinó la tecnología de comunicación remota para permitir la comunicación entre los
                                clientes y los ingenieros de Zion Tech a través de Internet y brindarles orientación en
                                tiempo real. Zion Tech puede ayudar a los clientes con la gestión práctica de la
                                producción, el análisis de datos y el mantenimiento diario del equipo. Además, todo el
                                sistema de botellas de PET está equipado con un clasificador óptico de botellas y un
                                clasificador de escamas, lo que reduce en gran medida la inversión en mano de obra y
                                mejora la eficiencia de producción, lo que realmente logra un sistema de reciclaje
                                limpio y altamente automatizado.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Destacar</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                <ul>
                                    <li>Aplicación de módulo estándar modular;</li>
                                </ul>
                                <ul>
                                    <li>
                                        Patente para el proceso de prelavado y circulación de agua de toda la botella;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Función de prelavado de escamas de botellas;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Potente función de lavado en caliente de escamas de botella;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Sistema de recuperación de material auxiliar en línea;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Tratamiento de agua en línea y sistema de purificación por circulación;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Producción más limpia y estandarización de talleres;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Disposición de línea completa móvil;
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Baja tasa de fallas de puesta en marcha, orientación de gestión de producción
                                        para la operación de toda la línea
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-9000 kg / h
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">

                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
